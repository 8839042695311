<template >
        <div class="applyCodeDivBg" v-if="translationloaded==true">
                <div class="subScribeWrapper">
                    <input :placeholder="translate('enterVoucherCode')" name="" type="text" v-model="offerCode" />
                    <button class="subScribeWrapperDiv"  @click="applyCoupon()"> {{translate('apply')}}</button>
                    <div></div>
                </div>
                <div class="applyCodeDivBgPattern">
                    <template v-if="couponCount > 0">
                         <!-- <p data-toggle="modal" data-target="#promocode-view"> {{couponCount}}  {{translate('promocodeApplicable')}}</p> -->
                         <a href="/my-coupons"> <p> {{couponCount}}  {{translate('promocodeApplicable')}}</p></a>
                        <!-- <p class="click-Me"> {{couponCount}}  {{translate('promocodeApplicable')}}</p> -->
                        <!-- <div class="my-para">
                            <span>this is my para</span>
                        </div> -->
                    </template>
                    <template v-else>
                         <p> {{couponCount}} {{translate('promocodeApplicable')}} </p>
                    </template>
                   
                </div>

         </div>
         <!-- <div id="promocode-view" class="modal">
                    Lorem ipsum dolor text....
        </div> -->
        
          <router-link :to="`/products`" class="continueShopBtnMain" > 
                <span><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></span>{{translate('continueShopping')}}
          </router-link>
        
         
</template>
<script>
export default {
    name:'ApplyPromoCode',
    props:[
        'selectedCoupon',
        'coupon_list',
        'cart_summary',
         'translationloaded',
        'translate'
    ],
    watch:{
        selectedCoupon(couponcode){
            this.offerCode =couponcode;
        },
        cart_summary(cartSummary){
            if(cartSummary !=''){
                this.offerCode = cartSummary.cpcode;
            }
            
        }
    },
    data(){
        return {
            'offerCode':''
        }
    },
    computed:{
        couponCount(){
            let couponCount =0;
            if(this.coupon_list!=0){
                 couponCount =this.coupon_list.length;
            }
            return couponCount;
        }
    },
    methods:{
        applyCoupon(){
            this.$emit('applayCoupon',this.offerCode);
        }
    }
}


</script>
<style scoped>
    
</style>