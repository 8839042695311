<template lang="">
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="ps-fullcart-view" v-if="translationloaded==true">
    <div class="container">
      <div class="ps-section__content">
        <h3 class="cartHead">{{translate('shoppingCart')}} ({{ getItemCnt }})</h3>

        <div class="subCartDivMainall1">
          <div class="cartFloatAllMain1 ">
            <div class="cartHederBorderMain headerCartmain">
              <div class="headerFloat1">
                <h3>{{translate('item')}}</h3>
              </div>
              <div class="headerFloat2">
                <h3>{{translate('quantity')}}</h3>
              </div>
              <div class="headerFloat2">
                <h3>{{translate('savings')}}</h3>
              </div>
              <div class="headerFloat2">
                <h3>{{ translate('subtotal')}}</h3>
              </div>
            </div>

            <CartListItem
              :cartData="getcartList"
              @updateCartQantity="updateCart"
              @deleteCartItem="rmvCartItem"
              :cart_ajax="cart_ajax"
              @placetoSavelater="addtoSavelater"
              :qtyReset="qtyReset"
              @cartQtyCntReset="qtyCntReset"
              :translationloaded="translationloaded"
              :translate="translate"
            ></CartListItem>
          </div>

          <div class="subCartDivMain">
            <h3>{{ translate('OrderSummary')}}</h3>
            <OrderSummary
              :cart_summary="getCartSummaryList"
              :cartData="getcartList"
              :getItemCnt="getItemCnt"
              :translationloaded="translationloaded"
              :translate="translate"
            ></OrderSummary>

            <p class="priceRangeTxtPara" v-if="getcartList!='' && getCartSummaryList.FreeDeliveryCharge">
             {{ translate('freeDeliveryAvailableForPurchaseAbove')}}
              <i>{{ currency }} {{getCartSummaryList.FreeDeliveryCharge}}</i>
              
            </p>
            <h3>{{ translate('applyPromoCode')}}</h3>
            <ApplyPromoCode
              :cart_summary="getCartSummaryList"
              :selectedCoupon="selectedCoupon"
              :coupon_list="coupon_list"
              @applayCoupon="applyOffer"
              :translationloaded="translationloaded"
              :translate="translate"
            >
            </ApplyPromoCode>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="why-shop-us-sec">
        <h4> {{ translate('whyShopFromKhairatLebanon')}}</h4>

        <div class="floatRefund1">
          <div class="refundWrapperMain">
            <div class="refundWrapperMainDiv">
              <img src="/assets/img/refund-icon.png" />
            </div>
            <p>
             {{translate('easyReturnsAndRefund')}}  {{translate('returnProductsAtdoorstepAndGetRefund')}}
            </p>
          </div>
        </div>
        <div class="floatRefund1">
          <div class="refundWrapperMain">
            <div class="refundWrapperMainDiv">
              <img src="/assets/img/rupees-icon.png" />
            </div>
            <p>
               {{translate('bestQualityProducts')}}
               {{translate('getBestQualityProductAtLowestPrice')}}
             
            </p>
          </div>
        </div>
        <div class="floatRefund1">
          <div class="refundWrapperMain">
            <div class="refundWrapperMainDiv">
              <img src="/assets/img/quick-delivery-icon.png" />
            </div>
            <p>
               {{translate('quickDelivery')}}
                {{translate('getProductsDeliveredtoYourDoorstepInNoTime')}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <template v-if="fetchSacedCartItemCnt!='0 item'">
          <div class="container" v-if="isLogined">
            <div class="saveForLaterBorder">
              <h3>{{translate('saveForLater')}} ({{fetchSacedCartItemCnt}})</h3>
              <div class="cartHederBorderMain saveLaterMaxWidth">
                    <SavedCartItem
                    :savedcartData="savedCartData"
                    @updateCartQantity="updateCart"
                    @deleteCartItem="rmvCartItem"
                    :cart_ajax="cart_ajax"
                    @placetoSavelater="addtoSavelater"
                    :translationloaded="translationloaded"
                    :translate="translate"
                  ></SavedCartItem>
              
              </div>
            </div>
    </div>
    </template>

    <div style="clear: both;"></div>

    <div class="ps-deal-of-day mb-4" v-if="getPopularProducts">
        <div class="ps-container">
          <div class="ps-section__header">
            <div class="ps-block--countdown-deal">
              <div class="ps-block__left">
                <h3> {{translate('bestSellers')}}</h3>
              </div>
            </div>
          </div>

          <div class="ps-section__content">
            <div class="ps-carousel--nav owl-slider">
              <ProductSlider
                :products="getPopularProducts"
                :offerBadge="true"
                :newBadge="true"
                @refreshPage="refreshData"
                 :translationloaded="translationloaded"
                  :translate="translate"
              ></ProductSlider>
            </div>
          </div>
        </div>
      </div>

    <!-- <div class="ps-deal-of-day mb-4" v-if="getRecentProducts">
      <div class="ps-container">
        <div class="ps-section__header">
          <div class="ps-block--countdown-deal">
            <div class="ps-block__left">
              <h3>{{translate('inspiredByYourBrowsingHistory')}}</h3>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>


        <div class="ps-section__content">
          <div class="ps-carousel--nav owl-slider">
            <ProductSlider
              :products="getRecentProducts"
              :offerBadge="true"
              :newBadge="true"
              @refreshPage="refreshData"
               :translationloaded="translationloaded"
              :translate="translate"
            ></ProductSlider>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <!-- <div  class="container">
    <div class="ps-deal-of-day mb-4" v-if="personalCareProduct">
        <div class="ps-container">
          <div class="ps-section__header">
            <div class="ps-block--countdown-deal">
              <div class="ps-block__left">
                <h3>Best Seller in Helth & personal care</h3>
              </div>
            </div>
          </div>

          <div class="ps-section__content">
            <div class="ps-carousel--nav owl-slider">
              <ProductSlider
                :products="personalCareProduct"
                :offerBadge="true"
                :newBadge="true"
                @refreshPage="refreshData"
              ></ProductSlider>
            </div>
          </div>
        </div>
      </div>
  </div> -->

  <div class="prdt-footer-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="prdt-footer-box">
            <div class="prdt-footer-img">
              <img src="/assets/img/assortment-icon.png" />
            </div>
            <p>
              <span class="txt-new-sub">Wide Assortment</span> <br />
              Choose from 5000+ products across food, personal care, <br />
              household &amp; other categories.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="prdt-footer-box">
            <div class="prdt-footer-img">
              <img src="/assets/img/price-offer-icon.png" />
            </div>
            <p>
              <span class="txt-new-sub">Wide Assortment</span> <br />
              Choose from 5000+ products across food, personal care, <br />
              household &amp; other categories.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="prdt-footer-box">
            <div class="prdt-footer-img">
              <img src="/assets/img/easy-return-icon.png" />
            </div>
            <p>
              <span class="txt-new-sub">Wide Assortment </span><br />
              Choose from 5000+ products across food, personal care, <br />
              household &amp; other categories.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OfferPopup
    :coupon_list="coupon_list"
    @coupon_code="getSelectedCoupon"
  ></OfferPopup>
   <span class="scrollTop" @click="topToBottom">
                          <i class="fa fa-angle-up" aria-hidden="true"></i>
    </span>
</template>
<script>
import CartListItem from "@/components/cart/cartListItem";
import SavedCartItem from "@/components/cart/SavedCartitem";
import OrderSummary from "@/components/cart/OrderSummary";
import ApplyPromoCode from "@/components/cart/ApplyPromoCode";
import Breadcrumb from "@/components/Breadcrumb";
import OfferPopup from "@/components/cart/OfferPopup";
import helper from "@/helper/helper";
import ProductSlider from "@/components/ProductSlider";
import { mapActions, mapGetters } from "vuex";
import WhatsAppButton from "@/components/WhatsAppButton";

import $ from "jquery";

import Config from "@/config";
import axios from "axios";
export default {
  name: "CartFullView",
  props: ["customerCartData", "cart_summary",'translationloaded',
      'translate'],
  components: {
    CartListItem,
    OrderSummary,
    ApplyPromoCode,
    Breadcrumb,
    OfferPopup,
    ProductSlider,
    SavedCartItem,
    WhatsAppButton
  },

  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },
        {
          path: "cart",
          name: "Cart",
        },
      ],
      cart_info: [],
      cart_ajax: "closed",
      isLogined: false,
      store_name: "",
      coupon_list: [],
      selectedCoupon: "",
      currency: "",
      guest_id: "",
      cust_id: null,
      admin_api_header: "",
      personalCareProduct: [],
      savedCartData:[],
      qtyReset:0
    };
  },
  mounted() {
    this.setPopularProduct();
    this.isLogined = helper.isLoginedUser();
    this.store_name = Config.STORE_NAME;
    this.storeCartSummary();
    this.getCustomerCouponList();
    this.storeRecentViewedProducts();
      let payload ={
        "custId":localStorage.getItem("custId"),
        "guestId":(localStorage.getItem("custId")=='null'?localStorage.getItem("guestId"):'null'),
      
        
      }
      let $this = this; 
    $this.getSaveCartList(payload).then(function(response){
    
      //  console.log(response);

         $this.savedCartData = response.data.Data;
    });
    $this.currency = Config.CURRECNCY;
   
    $this.guest_id = localStorage.getItem("guestId");
    $this.cust_id = localStorage.getItem("custId");
    helper.backtoTop();

    $this.admin_api_header = Config.ADMIN_API_HEADER;
    axios
      .get(
        `${Config.BASE_URL}RelatedProducts?urlKey=after-shave-malizia&custId=${$this.cust_id}&guestId=${$this.guest_id}`,
        $this.admin_api_header
      )
      .then(function(response) {
        $this.personalCareProduct = response.data.Data.RelatedList;
      });

    

        let data = {
          cusId:$this.cust_id,
          guestId: $this.guest_id,
          vendorUrlkey: Config.VENDOR_URL_KEY,
        };

        $this.getCart(data);
  },
  created() {},
  computed: {
    ...mapGetters([
      "getCartSummaryList",
      "getcartList",
      "getPopularProducts",
      "getRecentProducts",
      "fetctsavedCartItems",
      "getPopularProducts"
     
    ]),
    
    getItemCnt() {
      let itemCnt = 0;
      let itemCntHtml = "";
      if (this.getcartList != "") {
        itemCnt = this.getcartList.length;
      }
      if (itemCnt == 0) {
        itemCntHtml = `0 ${this.translate('item')}`;
      } else {
        itemCntHtml = `${itemCnt} ${this.translate('items')}`;
      }

      return itemCntHtml;
    },
   
    fetchSacedCartItemCnt(){
      let savedCartitem =0;
      let savedcartHtml ="";
      if(this.fetctsavedCartItems !=""){
        savedCartitem = this.fetctsavedCartItems.length;
      }
      if(savedCartitem == 0){
         savedcartHtml = "0 item";
      }else{
         savedcartHtml = `${savedCartitem} items`;
      }

      return savedcartHtml;
    }
  },
  methods: {
    ...mapActions(["storeCartSummary", "storeRecentViewedProducts","addToCartSavelater","getSaveCartList","removeSaveCartItem","getCart","setPopularProduct"]),
    topToBottom(){
       helper.backtoTop();
    },
    updateCart(cartItemInfo) {
      let $this = this;
      let cartUpdateUrl = "";
      if (cartItemInfo.type == "add") {
        cartUpdateUrl = `${Config.BASE_URL}Order/CartItemAddQty?cartItemId=${cartItemInfo.cart_item_id}`;
      } else if (cartItemInfo.type == "sub") {
        cartUpdateUrl = `${Config.BASE_URL}Order/CartItemSubQty?cartItemId=${cartItemInfo.cart_item_id}`;
      }
      if (cartUpdateUrl != "") {
        axios
          .get(`${cartUpdateUrl}`, Config.ADMIN_API_HEADER)
          .then(function(response) {
            if (response.status == 200) {
              let guestId = null;
              let custId = null;
              custId = localStorage.getItem("custId");
              if (custId == null) {
                guestId = localStorage.getItem("guestId");
              }

              let payload = {
                cusId: custId,
                guestId: guestId,
                vendorUrlkey: Config.VENDOR_URL_KEY,
              };
              $this.$store.dispatch("getCart", payload).then(function() {
                $this.cart_info = $this.$store.state.customer_cart;
                $this.$emit("cartUpdated", $this.cart_info);
                $this.storeCartSummary();
              });
            }
          })
          .catch(function(error) {
            $this.$toast.error(`${error.response.data.Message}`);
            $this.qtyReset =1; 
          });
      }
    },
     qtyCntReset(){
      this.qtyReset=0;
    },
    rmvCartItem(cartItemId) {
      let $this = this;
      $this.cart_ajax = "open";
      $this.$store.dispatch("removeCartItem", cartItemId).then(function() {
        let customerCart = $this.$store.state.customer_cart;
        $this.customer_cart_data = customerCart;
        $this.$emit("cartUpdated", customerCart);
        $this.storeCartSummary();
        $this.cart_ajax = "closed";
      });
    },
    getCustomerCouponList() {
      let $this = this;
      $this.$store.dispatch("getCustomerCouponList").then(function(response) {
        $this.coupon_list = response.data.Data;
      });
    },
    getSelectedCoupon(coupon) {
      this.selectedCoupon = coupon;
      $(".icon-cross2").trigger("click");
      this.applyCustomerOffer();
    },
    applyOffer(offerCode) {
      this.selectedCoupon = offerCode;
      this.applyCustomerOffer();
    },
    applyCustomerOffer() {
      let $this = this;
      $this.$store
        .dispatch("applyCustomerOffer", this.selectedCoupon)
        .then(function(response) {
          $this.$toast.success(`${response.data.Message}`);
        })
        .catch(function(error) {
          $this.$toast.error(`${error.response.data.Message}`);
        });
    },
    addtoSavelater(cartItem){
      let payload ={
        "custId":localStorage.getItem("custId"),
        "guestId":(localStorage.getItem("custId")=='null'?localStorage.getItem("guestId"):'null'),
        "urlKey":cartItem.urlKey,
        "cartItemId":cartItem.cartItemsId,
        "cart_item_id":cartItem.cartItemsId
      }
      let $this =this;
      $this.addToCartSavelater(payload).then(function(response){
            if(response.status==200){
              $this.getSaveCartList(payload);
            }
      });
    },
    
  },
};
</script>
<style scoped>

.proceedBtnMain{
  cursor: pointer;
}
.cartHead{
  text-transform: uppercase;
}
</style>
